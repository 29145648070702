import React, { useState } from "react";
import { MobileDateTimePicker } from "@mui/x-date-pickers/MobileDateTimePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import dayjs from "dayjs";
// import { ReactComponent as DateTime } from "../assets/images/date.svg";
import { LuCalendarClock } from "react-icons/lu";

function CustomLabelPicker({ index, selectedDate, onChange = (value) => {}, color }) {
  const [open, setOpen] = useState(false); // State to control the visibility of the date picker

  const parsedDate = selectedDate ? dayjs(selectedDate) : null;

  return (
    <>
      <LocalizationProvider key={index}>
        <LuCalendarClock
          onClick={() => {
            setOpen(true);
          }}
          style={{ cursor: "pointer", height: "24px", width: "24px", color }}
        />

        <MobileDateTimePicker
          key={index}
          sx={{
            display: "none",
          }}
          open={open}
          onClose={() => {
            setOpen(false);
          }}
          //   value={moment(new Date(selectedDate))}
          value={parsedDate}
          onChange={(val) => onChange(new Date(val))}
          renderInput={() => null}
          slots={{ textField: () => null }}
        />
      </LocalizationProvider>
    </>
  );
}

export default CustomLabelPicker;
