import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  loading: false,
  tag: {
    selectedTags: [],
    tags: [],
  },
  filter: {
    pinned: false,
    favorite: false,
    reminder: false,
    filter: false,
    all_filter: { sortBy: "name", selectedTags: [], selectedNotes: "All" },
    search: "",
  },
  // Add New Object For Filter With Props IN Innitial State
};

export const UserSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    SET_LOADING: (state, action) => {
      state.loading = action.payload;
    },
    SET_TAGS: (state, action) => {
      state.tag.tags = action.payload;
    },
    SET_SELECTED_TAGS: (state, action) => {
      state.tag.selectedTags = action.payload;
    },
    SET_PINNED: (state, action) => {
      state.filter.pinned = action.payload;
    },
    SET_FAVORITE: (state, action) => {
      state.filter.favorite = action.payload;
    },
    SET_REMINDER: (state, action) => {
      state.filter.reminder = action.payload;
    },
    SET_FILTER: (state, action) => {
      state.filter.filter = action.payload;
    },
    SET_ALL_FILTER: (state, action) => {
      state.filter.all_filter = action.payload;
    },
    SET_SEARCH: (state, action) => {
      state.filter.search = action.payload;
    },
    // Add New Method For Set That Object And Export That Methods
    // FOR EXAMPLE:
    // SET_OBJ_KEY : (state,action)=>{
    //   state.OBJ.KEY = action.PAYLOAD
    // }
  },
});

export const {
  SET_LOADING,
  SET_TAGS,
  SET_SELECTED_TAGS,
  SET_PINNED,
  SET_FAVORITE,
  SET_REMINDER,
  SET_FILTER,
  SET_ALL_FILTER,
  SET_SEARCH
} = UserSlice.actions;

export default UserSlice.reducer;

// USAGE:
//   const dispatch = useDispatch();
// dispatch(SET_SELECTED_TAGS(data));
// dispatch(SET_TAGS(data));
// dispatch(SET_LOADING(true||false));

// const selectedTags = useSelector((state) => state.USER_DATA.tag.selectedTags);
// const tags = useSelector((state) => state.USER_DATA.tag.tags);
// const loading = useSelector((state) => state.USER_DATA.loading);
